<template>
   <v-container 
      style="max-width: 2000px;padding-top:66px;background-color:white;height:100vh;width:100vw;overflow-y:auto;overflow-x:hidden;"
      class="bgImage"
      :style="{
         'background-image': backgroundImg?backgroundImg:'none',
         'background-color': backgroundColor?backgroundColor:'none'
         }"
      >
      <v-row class="pa-1 no-blur">
         <v-col align="center" cols="12">
            <v-card style="max-width:600px;">
               <v-card-title class="py-1 px-3"
                  style="color:#666666;font-weight:600;border-bottom: 1px solid #CCCCCC;">
                  <v-icon class="mr-2">mdi-cog</v-icon>
                  Thông tin cá nhân
                  <v-spacer/>
                  <v-btn dark 
                     class="px-3 text-none"
                     color="#1565C0"
                     @click="saveUserInfo">
                     <b>Áp dụng</b>
                  </v-btn>
               </v-card-title>

               <v-card-text class="px-6 py-1"
                  style="height:calc(100vh - 160px);max-height:calc(100vh - 160px);overflow-y:auto;">
                  <v-row class="pt-4"> 

                     <v-col cols="12" class="pa-0">
                        <v-card-subtitle
                           class="pa-0 py-1 px-4"
                           v-show="showError"
                        >
                           <div :style="{'background-color': errForm.color}"
                              style="font-size:16px;font-weight: 600;border-radius:4px;"
                              class="py-1 d-block white--text text-center">
                              {{ errForm.message }}
                           </div>
                        </v-card-subtitle>
                     </v-col>

                     <v-col v-if="user"
                        cols="12" class="mt-1 pa-0 px-4">
                        <v-text-field
                           ref="fullname"
                           style="font-size:18px;font-weight:600;"
                           v-model="user.fullname"
                           :rules="[rules.required, rules.minFullname]"
                           label="Họ và tên"
                           outlined dense
                           @focus="showKeyboard"
                           ></v-text-field>
                     </v-col>

                     <v-col v-if="user"
                        cols="12" class="mt-1 pa-0 px-4">
                        <v-text-field
                           style="font-size:18px;font-weight:600;"
                           v-model="user.mobile"
                           label="Số điện thoại"
                           outlined dense
                           @focus="showKeyboard"
                           ></v-text-field>
                     </v-col>

                     <v-col v-if="user"
                        cols="12" class="mt-1 pa-0 px-4">
                        <v-text-field
                           style="font-size:18px;font-weight:600;"
                           v-model="user.email"
                           label="Email"
                           outlined dense disabled
                           @focus="showKeyboard"
                           ></v-text-field>
                     </v-col>

                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>


<script>
import { mapGetters } from "vuex"
export default {
   props: ['modal', 'customStyle'],
   computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "getLevel",
      }),
      showError() {
        return this.errForm.status
      },
   },
   data () {
      return {
         backgroundImg: null,
         backgroundColor: null,
         blurBackground: true,
         socketAskKey: new Date().getTime(),
         user: null,
         showOldPassword: false,
         oldPassword: "",
         newPassword: "",
         showNewPassword: false,
         confirmPassword: "",
         errForm: {
            status: false,
            message: "",
            color: 'red'
         },
         rules: {
            required: (value) => !!value || "*Bắt buộc",
            minFullname: (v) => v.length >= 3 || "Ít nhất 3 ký tự",
            minPassword: (v) => v.length >= 6 || "Ít nhất 6 ký tự",
            confirmPass: (value) =>
               (value && value == this.newPassword) ||
               "Mật khẩu không khớp!",
         },
      }
   },
   watch: {
      showError: function () {
         if (this.showError) {
            let self = this
            setTimeout(function () {
               self.errForm.status = false
            }, 5000)
         }
      }
   },
   methods: {
      resetValidate () {
        if (
          this.$refs.fullname != undefined &&
          !this.$refs.fullname.validate()
        ) {
          this.$refs.fullname.reset();
        }
      },
      isValidate () {
        let result = true;
        this.$refs.fullname.validate(true)

        if (
          !this.$refs.fullname.validate()
        ) {
           
          this.errForm.status = true
          setTimeout(() => {
            this.errForm.status = false
          }, 3000);
          this.errForm.color = 'red'
          this.errForm.message = "Lỗi! Thông tin không hợp lệ!"
          result = false;
        }
        return result;
      },
      fetchUserInfo () {
         if (this.userId && this.token) {
         this.axios
            .get(this.$root.apiUser + "/user/" + this.userId, {
               headers: { "x-token": this.token },
            })
            .then((res) => {
               if (res.data.status == "OK") {
                  this.user = res.data.content
               }
            })
            .catch(err => {
               if ( err.response) {
                  console.log("Lỗi! " + err.response.data.message)
                  // this.showMessageApp("Lỗi! " + err.response.data.message, "error")
                  return
               }
               console.log("Lỗi! " + err.message)
               // this.showMessageApp("Lỗi! " + err.message, "error")
            });
         }
      },
      saveUserInfo () {
        if (!this.isValidate()) return;
        var self = this
        let payload = {
          customer_id: this.$root.apiUserCustomerId,
          fullname: this.user.fullname,
          mobile: this.user.mobile,
          email: this.user.email,
        };
        self.axios
            .put( this.$root.apiUser + `/user/${this.user._id}`,
               payload,
               { headers: { "x-token": self.token } })
            .then((res) => {
               // console.log(res.data)
               if (res.data.status == 'OK') {
                  self.errForm.message = "Lưu thành công!"
                  self.errForm.color = '#689F38'
                  self.errForm.status = true
               } else {
                  self.errForm.message = res.data.message
                  self.errForm.color = 'red'
                  self.errForm.status = true
               }
            }).catch(err => {
               self.errForm.color = 'orange'
               self.errForm.status = true
               if( err.response ){
                  // console.log(err.response.data)
                  self.errForm.message = err.response.data.message
                  return
               }
               self.errForm.message = err.message
            })
      },
      showKeyboard (e) {
         this.$eventBus.$emit("requestShowKeyboard", e.target, 'normal')
      }
   },
   created () {
      this.fetchUserInfo()
   }
}
</script>

<style>
   
</style>